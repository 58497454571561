import { faInfoCircle, faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import loadable from "@loadable/component";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import React, { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";

import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalHeader,
  Row,
  UncontrolledAlert,
} from "reactstrap";
import LoaderLottie from "../assets/lotties/loader_v2.json";
import noInfo from "../assets/lotties/no-info.json";

import CustomerSupportWhatsapp from "../components/chatbot/CustomerSupportWhatsapp";
import Dashboard from "../components/dashboard/Dashboard";
import ProductProvider from "../components/e-commerce/ProductProvider";
import Footer from "../components/footer/Footer";
import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import AppContext from "../context/Context";
import { getPageName } from "../helpers/utils";
import { auth } from "../index";
import "./dashboardLayout.css";

const DashboardRoutes = loadable(() => import("./DashboardRoutes"));

const DashboardLayout = ({ location }) => {
  const { isFluid, isTopNav, navbarStyle, isDark, setIsDark, showBurgerMenu, setShowBurgerMenu } =
    useContext(AppContext);
  const [authUser, setAuthUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [locations, setLocations] = useState([]);
  const [locScan, setLocScan] = useState("INVALID");
  const [franchises, setFranchises] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [fullClaims, setFullClaims] = useState([]);
  const [claims, setClaims] = useState([]);
  const [fullVisits, setFullVisits] = useState([]);
  const [visits, setVisits] = useState([]);
  const [automationCosts, setAutomationCosts] = useState(null);

  const [pulse, setPulse] = useState(false);

  const [noLogoBanner, setNoLogoBanner] = useState(false);
  const [bannerShown, setBannerShown] = useState(false);

  const [checklistStage, setChecklistStage] = useState(null);
  const [checklistIndex, setChecklistIndex] = useState(0);

  const checklistSteps = [
    { number: 1, title: "upload-logo" },
    { number: 2, title: "loyalty" },
    { number: 3, title: "coupon" },
    { number: 4, title: "team" },
    { number: 5, title: "app" },
    { number: 6, title: "done" },
  ];

  const [selectedLocation, setSelectedLocation] = useState("all");
  const [girosNegocio, setGirosNegocio] = useState([]);
  const [isRegisterBannerOpen, setIsRegisterBannerOpen] = useState(false);
  const [daysLeft, setDaysLeft] = useState(0);
  const [signLink, setSignLink] = useState("");

  const isKanban = getPageName("kanban");
  const history = useHistory();

  const firestore = firebase.firestore();

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const updateStepFirebase = (step) => {
    if (companyData.companyID != null && companyData.companyID != undefined) {
      firestore.collection("companies").doc(companyData.companyID).update({
        onboardingChecklistStage: step,
      });
    }
  };

  const getCompanyRewards = (companyID) => {
    return firestore.collection("companies").doc(companyID).collection("rewards").limit(1).get();
  };

  const getCompanyCoupons = (companyID) => {
    return firestore.collection("companies").doc(companyID).collection("coupons").limit(1).get();
  };

  const skipStep = () => {
    if (checklistIndex === 6) {
      setChecklistIndex(6);
      setChecklistStage("done");
      updateStepFirebase("done");
    } else {
      if (checklistIndex + 1 == 1) {
        history.push("/store");
      } else if (checklistIndex + 1 == 2) {
        history.push("/lealtad/visitas");
      } else if (checklistIndex + 1 == 3) {
        history.push("/promotions/coupons");
      } else if (checklistIndex + 1 == 4) {
        history.push("/store/team");
      } else {
        history.push("/storefront");
      }
      setChecklistStage(checklistSteps[checklistIndex].title);
      setChecklistIndex(checklistIndex + 1);
      updateStepFirebase(checklistSteps[checklistIndex].title);
    }
  };

  const getOnboardingStage = async (currentStage) => {
    if (currentStage) {
      if (currentStage == "done") {
        setChecklistStage("done");
        return "done";
      }
      setChecklistIndex(checklistSteps.findIndex((step) => step.title === currentStage) + 1);
    }

    if (
      companyData.companyLogoUrl == "" ||
      companyData.companyLogoUrl == null ||
      companyData.companyLogoUrl == undefined
    ) {
      setChecklistIndex(1);
      updateStepFirebase("upload-logo");
      setChecklistStage("upload-logo");
      return "upload-logo";
    } else {
      await getCompanyRewards(companyData.companyID).then(async (querySnapshot) => {
        if (querySnapshot.size == 0) {
          setChecklistIndex(2);
          updateStepFirebase("loyalty");
          setChecklistStage("loyalty");
          return "loyalty";
        } else {
          await getCompanyCoupons(companyData.companyID).then((querySnapshot) => {
            if (querySnapshot.size == 0) {
              setChecklistIndex(3);
              updateStepFirebase("coupon");
              setChecklistStage("coupon");
              return "coupon";
            } else {
              if (companyData.members.length <= 1) {
                setChecklistIndex(4);
                updateStepFirebase("team");
                setChecklistStage("team");
                return "team";
              } else {
                setChecklistIndex(4);
                updateStepFirebase("done");
                setChecklistStage("done");
                return "done";
              }
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    function getCompanyData(companyID) {
      return firestore.collection("companies").doc(companyID).get();
    }

    async function getClaimsData(companyID, locationIds) {
      var today = new Date();
      today.setDate(today.getDate() - 60);
      today.setHours(0, 0, 0, 0);

      const limit = 10;
      var claimsData = [];
      if (locationIds.length < 10) {
        return firestore
          .collection("companies")
          .doc(companyID)
          .collection("claimsLog")
          .where("time", ">=", today)
          .where("location", "in", locationIds)
          .orderBy("time", "desc")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              setClaims((c) => [...c, { ...doc.data(), id: doc.id }]);
              setFullClaims((c) => [...c, { ...doc.data(), id: doc.id }]);
            });
          });
      } else {
        while (locationIds.length) {
          await firestore
            .collection("companies")
            .doc(companyID)
            .collection("claimsLog")
            .where("time", ">=", today)
            .where("location", "in", locationIds.slice(0, limit))
            .orderBy("time", "desc")
            .get()
            .then((res) => {
              res.forEach((doc) => {
                setClaims((c) => [...c, { ...doc.data(), id: doc.id }]);
                setFullClaims((c) => [...c, { ...doc.data(), id: doc.id }]);
              });
              locationIds.splice(0, limit);
            });
        }
      }
    }

    async function getVisitData(companyID, locationIds) {
      var today = new Date();
      today.setDate(today.getDate() - 60);
      today.setHours(0, 0, 0, 0);
      const limit = 10;
      var rewardsData = [];

      if (locationIds.length < 10) {
        return (
          firestore
            .collection("companies")
            .doc(companyID)
            .collection("rewardsLog")
            .where("date", ">=", today)
            .where("location", "in", locationIds)
            // .orderBy("date", "desc")
            .get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                setVisits((v) => [...v, { ...doc.data(), id: doc.id }]);
                setFullVisits((v) => [...v, { ...doc.data(), id: doc.id }]);
              });
            })
        );
      } else {
        while (locationIds.length) {
          await firestore
            .collection("companies")
            .doc(companyID)
            .collection("rewardsLog")
            .where("date", ">=", today)
            .where("location", "in", locationIds.slice(0, limit))
            // .orderBy("date", "desc")
            .get()
            .then((res) => {
              res.forEach((doc) => {
                rewardsData.push({ ...doc.data(), id: doc.id });
                // setVisits0((v) => [...v, { ...doc.data(), id: doc.id }]);
                // setFullVisits((v) => [...v, { ...doc.data(), id: doc.id }]);
              });
              locationIds.splice(0, limit);
            });
        }
        rewardsData.sort((a, b) => a.date - b.date);
        setVisits(rewardsData);
        setFullVisits(rewardsData);
      }
    }

    function getCompanyLocations(companyID, uid, permissionLevel, franchiseID) {
      if (permissionLevel < 3) {
        firestore
          .collection("companies")
          .doc(companyID)
          .collection("locations")
          .where("memberUids", "array-contains", uid)
          .get();
      } else if (franchiseID) {
        return firestore
          .collection("companies")
          .doc(companyID)
          .collection("franchises")
          .doc(franchiseID)
          .get()
          .then((data) => {
            const { locations } = data.data();
            return firestore
              .collection("companies")
              .doc(companyID)
              .collection("locations")
              .where(firebase.firestore.FieldPath.documentId(), "in", locations)
              .get();
          });
      }

      return firestore.collection("companies").doc(companyID).collection("locations").get();
    }

    function getCompanyFranchises(companyID, uid, permissionLevel) {
      return firestore.collection("companies").doc(companyID).collection("franchises").get();
    }

    function getUserNotifications(uid) {
      return firestore.collection("posUsers").doc(uid).collection("notifications").get();
    }

    function getGirosNegocio() {
      return firestore.collection("giros").get();
    }

    function getLegalDocuments(uid) {
      return firestore
        .collection("posUsers")
        .doc(uid)
        .collection("documentation")
        .doc("legalDocuments")
        .get();
    }

    function getDaysSince(date) {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      var diff = Math.abs(today - date);
      var days = Math.floor(diff / 1000 / 60 / 60 / 24);
      return days;
    }

    let posUserInfo = {};
    let gCompanyID = null;
    let locationsArray = [];
    let posUserRegisterInfo = {};

    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        const { emailVerified } = authUser;

        setAuthUser(authUser);
        return firestore
          .collection("posUsers")
          .doc(authUser.uid)
          .get()
          .then((doc) => {
            posUserInfo = doc.data();

            const { businessRep, registrationComplete, registrationDate, documentsApproved } =
              doc.data();

            posUserRegisterInfo = {
              businessRep,
              registrationComplete,
              registrationDate,
              documentsApproved,
            };

            if (businessRep && !registrationComplete) {
              window.open("/register/b-onboarding", "_self");
              throw new Error("Este usuario no ha terminado el registro de su negocio.");
            }

            if (businessRep && !emailVerified) {
              window.open("/register/b-onboarding", "_self");
              throw new Error("Este usuario no ha verificado su correo");
            }

            return doc.data().companyID;
          })
          .then((companyID) => {
            gCompanyID = companyID;
            if (companyID === null) {
              window.open("/errors/512", "_self");
            }

            return getLegalDocuments(authUser.uid);
          })
          .then((legalDocuments) => {
            if (legalDocuments && legalDocuments.data()) {
              const { documentsApproved, signNowLink } = legalDocuments.data();

              // Check if its needed to show the register banner
              if (
                posUserRegisterInfo.businessRep &&
                posUserRegisterInfo.registrationComplete &&
                posUserRegisterInfo.registrationDate &&
                !documentsApproved
              ) {
                // Get days since registration
                const daysSinceRegistration = getDaysSince(
                  posUserRegisterInfo.registrationDate.seconds * 1000
                );

                // If its been more than 30 days since registration, show banner
                if (daysSinceRegistration > 0) {
                  setDaysLeft(30 - daysSinceRegistration);
                } else {
                  setDaysLeft(0);
                }

                if (signNowLink) {
                  setSignLink(signNowLink);
                }

                setIsRegisterBannerOpen(true);
              }
            }

            return getCompanyData(gCompanyID);
          })
          .then((doc) => {
            const { members } = doc.data();
            let permissionLevel = null;

            setCompanyData(doc.data());

            members.forEach((member) => {
              if (member.userUID === authUser.uid) {
                permissionLevel = member.permissionLevel;
                if (member.permissionLevel < 2) {
                  history.push("/e/download");
                }
                setUserData({ ...posUserInfo, memberInfo: member });
              }
            });

            return getCompanyLocations(
              doc.id,
              authUser.uid,
              permissionLevel,
              posUserInfo.franchiseId
            );
          })
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              locationsArray.push({ ...doc.data(), id: doc.id });
            });

            setLocations(locationsArray);

            return getCompanyFranchises(gCompanyID, authUser.uid, userData.permissionLevel);
          })
          .then((querySnapshot) => {
            const franchisesArray = [];

            querySnapshot.forEach((franchise) => {
              const franchisePrimaryLocation = locationsArray.find(
                (loc) => loc.id === franchise.data().primaryLocation
              );
              //////////////////////

              let franchiseData = {
                ...franchisePrimaryLocation,
                ...franchise.data(),
                id: franchise.id,
              };

              let franchiseMembers = [];

              franchiseData.locations.forEach((location) => {
                const currLoc = locationsArray.find((loc) => location === loc.id);

                if (currLoc) {
                  currLoc.members.forEach((member) => {
                    let exists = false;
                    for (let i = 0; i < franchiseMembers.length; i++) {
                      if (franchiseMembers[i].userUID === member.userUID) {
                        exists = true;
                      }
                    }
                    if (!exists) {
                      franchiseMembers.push(member);
                    }
                  });
                }
              });
              franchiseData.members = franchiseMembers;
              //////////////////////////
              franchisesArray.push(franchiseData);
            });
            setFranchises(franchisesArray);

            return getGirosNegocio();
          })
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              setGirosNegocio((g) => [...g, { ...doc.data(), id: doc.id }]);
            });
            return getUserNotifications(authUser.uid);
          })
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              setNotifications((p) => [...p, { ...doc.data(), id: doc.id }]);
            });

            return getClaimsData(
              gCompanyID,
              locationsArray.map((l) => l.id)
            );
          })
          .then((querySnapshot) => {
            // querySnapshot.forEach((doc) => {
            //   setClaims((c) => [...c, { ...doc.data(), id: doc.id }]);
            //   setFullClaims((c) => [...c, { ...doc.data(), id: doc.id }]);
            // });

            return getVisitData(
              gCompanyID,
              locationsArray.map((l) => l.id)
            );
          })
          .then(() => {
            // querySnapshot.forEach((doc) => {
            //   setVisits((v) => [...v, { ...doc.data(), id: doc.id }]);
            //   setFullVisits((v) => [...v, { ...doc.data(), id: doc.id }]);
            // });

            setIsLoading(false);
          })
          .catch((error) => {});
      } else {
        if (!(window.location.pathname === "/auth/logout")) {
          history.push("/landing");
        }
      }
    });
  }, []);

  useEffect(() => {
    if (selectedLocation === "all") {
      setVisits(fullVisits);
      setClaims(fullClaims);
    } else {
      setVisits(fullVisits.filter((l) => l.location === selectedLocation));
      setClaims(fullClaims.filter((c) => c.location === selectedLocation));
    }
  }, [selectedLocation]);

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    const today = new Date();
    console.log("registration Date", companyData.dateCreated);

    let onboardingChecklistStage = companyData.onboardingChecklistStage || null;

    getOnboardingStage(onboardingChecklistStage);

    if (companyData.dateCreated) {
      const diff = Math.abs(today - companyData.dateCreated.toDate());
      const days = Math.floor(diff / 1000 / 60 / 60 / 24);
      if (days > 8 && companyData.companyLogoUrl === "") {
        if (bannerShown === false) {
          setNoLogoBanner(true);
          setBannerShown(true);
        }
      }
    }
  }, [companyData]);

  useEffect(() => {
    let path = window.location.pathname.split("/")[1];

    if (checklistStage == "loyalty" && path !== "lealtad") {
      setPulse(true);
    } else if (checklistStage == "coupon" && path !== "promotions") {
      setPulse(true);
    } else if (checklistStage == "team" && path !== "store") {
      setPulse(true);
    } else if (checklistStage == "upload-logo" && path !== "store") {
      setPulse(true);
    } else if (checklistStage == "app" && path !== "storefront") {
      setPulse(true);
    } else {
      setPulse(false);
    }
  }, [checklistStage, window.location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  if (isLoading) {
    return (
      <Container fluid>
        <Row className="flex-center min-vh-100">
          <Col className="text-center">
            <Lottie options={lottieOptions} height={100} width={100} speed={2} />
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <div>
      <Modal size="lg" centered isOpen={noLogoBanner}>
        <ModalHeader
          toggle={(e) => {
            e.preventDefault();
            setNoLogoBanner(false);
          }}
        >
          <h4 className="text-center">¡No haz agregado la información de tu negocio!</h4>
        </ModalHeader>
        <div className="p-3">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: noInfo,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={100}
            width={100}
            speed={1}
          />

          <p className="text-center">
            Agrega la información básica de tu negocio para que tus clientes te reconozcan más
            fácilmente.
          </p>
          <div className="d-flex justify-content-center">
            <Button
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                setNoLogoBanner(false);
                history.push("/store");
              }}
            >
              Agregar información
            </Button>
          </div>
        </div>
      </Modal>

      {/* {isRegisterBannerOpen && (
        <div className="banner">
          <p>
            Recuerda, te restan {daysLeft} días para firmar el documento de
            prestación de servicios.
          </p>
          <div className="acciones">
            {signLink && (
              <Button
                color="falcon-default"
                size="sm"
                className="ml-2"
                href={signLink}
                target="_blank"
              >
                Firma aquí
              </Button>
            )}
            <Button
              color="danger"
              size="sm"
              className="ml-2 closeButtonLg"
              onClick={() => setIsRegisterBannerOpen(false)}
            >
              Cerrar
            </Button>
          </div>

          <span
            className="closeButton"
            onClick={() => setIsRegisterBannerOpen(false)}
          >
            x
          </span>
        </div>
      )} */}
      <div className={isFluid || isKanban ? "container-fluid" : "px-2 px-lg-6"}>
        {/* <div className={isFluid || isKanban ? "container-fluid" : "container"}> */}

        {!isTopNav && (
          <NavbarVertical
            isKanban={isKanban}
            navbarStyle={navbarStyle}
            permissionLevel={userData.memberInfo.permissionLevel}
            billingUser={companyData.billingUser}
            companyID={companyData.companyID}
            isRegisterBannerOpen={false}
            registeredPlan={companyData.plan}
            isPos={companyData.isPos}
          />
        )}
        <ProductProvider>
          <div className="content">
            {" "}
            <AppContext.Provider
              value={{
                isDark: isDark,
                setIsDark: setIsDark,
                ...userData,
                checklistStage: checklistStage,
                setChecklistStage: setChecklistStage,
                skipStep: skipStep,
                reloadChecklist: getOnboardingStage,
                companyData: { ...companyData },
                setCompanyData: setCompanyData,
                locations: locations,
                setLocations: setLocations,
                franchises: franchises,
                setFranchises: setFranchises,
                notifications: notifications,
                setNotifications: setNotifications,
                selectedLocation: selectedLocation,
                setSelectedLocation: setSelectedLocation,
                claims: claims,
                setClaims: setClaims,
                visits: visits,
                setVisits,
                automationCosts,
                setAutomationCosts,
                locScan: locScan,
                setLocScan: setLocScan,
                girosNegocio: girosNegocio,
                showBurgerMenu: showBurgerMenu,
                setShowBurgerMenu: setShowBurgerMenu,
                isTopNav: isTopNav,
              }}
            >
              <NavbarTop
                name={userData.name}
                permissionLevel={userData.memberInfo.permissionLevel}
              />
              {checklistStage !== "done" && checklistStage != null ? (
                <div>
                  <Card className="mb-3 shadow-lg border">
                    <CardHeader className="bg-light">
                      <h5 className="mb-0 fs-1">¡Pasos para el éxito de tu negocio!</h5>
                      <small>
                        Completa los siguientes pasos para que tu negocio tenga éxito en la
                        implementación de Swirvle.
                      </small>
                    </CardHeader>
                    <CardBody className="d-flex align-items-center justify-content-between">
                      <div>
                        <h5 className="mb-2 pb-0 pt-0 inline">
                          {checklistIndex} de 5:{" "}
                          {checklistStage == "upload-logo" && (
                            <span className="text-primary font-weight-semi-bold">
                              Sube tu logo y la información de tu negocio
                            </span>
                          )}
                          {checklistStage == "loyalty" && (
                            <span className="text-primary font-weight-semi-bold">
                              Crea una promoción por lealtad para tus clientes
                            </span>
                          )}
                          {checklistStage == "coupon" && (
                            <span className="text-primary font-weight-semi-bold">
                              Crea un cupón de bienvenida para incentivar a tus clientes a
                              participar
                            </span>
                          )}
                          {checklistStage == "team" && (
                            <span className="text-primary font-weight-semi-bold">
                              Invita a tu equipo a Swirvle para que puedan escanear las compras de
                              tus clientes
                            </span>
                          )}
                          {checklistStage == "app" && (
                            <span className="text-primary font-weight-semi-bold">
                              ¡Escanea los códigos QR de tus clientes!
                            </span>
                          )}
                        </h5>

                        <div>
                          <p>
                            {checklistStage == "upload-logo" && (
                              <span>
                                Sube tu logo y la información de tu negocio para que tus clientes te
                                reconozcan más fácilmente.
                              </span>
                            )}

                            {checklistStage == "loyalty" && (
                              <span>
                                Crea una promoción por lealtad y premia a tus clientes frecuentes.
                              </span>
                            )}

                            {checklistStage == "coupon" && (
                              <span>
                                Crea un cupón de bienvenida para hacer más atractivo a tus clientes
                                a participar en tu programa de lealtad.
                              </span>
                            )}

                            {checklistStage == "team" && (
                              <span>
                                Invita a tu equipo a Swirvle para que puedan escanear las compras de
                                tus clientes y canjear sus promociones.
                              </span>
                            )}

                            {checklistStage == "app" && (
                              <span>
                                Inicia a escanear los códigos QR de tus clientes para que puedan
                                acumular puntos y canjear recompensas.
                              </span>
                            )}
                          </p>
                        </div>
                      </div>

                      <div>
                        <Button
                          color="primary"
                          className={`mr-2 ${pulse == true ? "pulse-lg-blue" : ""}`}
                          onClick={() => {
                            if (checklistIndex == 1) {
                              history.push("/store");
                            } else if (checklistIndex == 2) {
                              history.push("/lealtad/visitas");
                            } else if (checklistIndex == 3) {
                              history.push("/promotions/coupons");
                            } else if (checklistIndex == 4) {
                              history.push("/store/team");
                            } else {
                              history.push("/storefront");
                            }
                          }}
                        >
                          Ir a completar
                        </Button>

                        {!(checklistIndex <= 2) && (
                          <Button
                            color="outline-secondary"
                            className="ml-auto"
                            onClick={() => {
                              skipStep();
                            }}
                          >
                            Omitir
                          </Button>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ) : (
                <div className="d-flex w-100 align-items-center">
                  {userData.memberInfo.permissionLevel >= 3 && (
                    <UncontrolledAlert
                      color="success"
                      style={{
                        flexGrow: 4,
                      }}
                      className="mx-1"
                    >
                      <FontAwesomeIcon icon={faRobot} className="mr-2" />
                      ¿Quieres vender en piloto automático?{" "}
                      <Link to="/automations/all">Descubre automatizaciones</Link>.
                    </UncontrolledAlert>
                  )}
                  {userData.memberInfo.permissionLevel >= 3 && !userData.constUrl && (
                    <UncontrolledAlert
                      color="warning"
                      style={{
                        flexGrow: 4,
                      }}
                      className="mx-1"
                    >
                      <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                      <Link to="/pagos/facturacion">¿Requieres factura?</Link>
                    </UncontrolledAlert>
                  )}
                </div>
              )}

              {(companyData.watchlistStage == "initial" ||
                companyData.watchlistStage == "scheduling-sos") && (
                <UncontrolledAlert color="danger">
                  <FontAwesomeIcon className="mr-2" />
                  <h5>¿Necesitas ayuda?</h5>
                  <a target="_blank" href="https://wa.me/528128826598">
                    Agenda una videollamada con nuestro equipo de Customer Success
                  </a>{" "}
                  para poder ayudarte con lo que necesites.
                  <b>
                    Te apoyamos con asesoramiento en promociones, configuración de tu cuenta,
                    capacitaciones para tu personal y más.
                  </b>
                </UncontrolledAlert>
              )}
              <Switch>
                <Route path="/" exact component={Dashboard} />
                {/* <Route path="/dashboard-alt" exact component={DashboardAlt} /> */}
                <DashboardRoutes />
              </Switch>
              <CustomerSupportWhatsapp />
            </AppContext.Provider>
            {!isKanban && <Footer />}
          </div>
          {/*<SidePanelModal path={location.pathname} />*/}
        </ProductProvider>
      </div>
    </div>
  );
};

export default DashboardLayout;
